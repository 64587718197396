

























































































































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { searchConfig } from './externalcontacts.config';
import { ISearchConfig } from '@/components/SearchTable/index.d';
import { UserUploadService } from '@/services/user-uploading';
import { cloneDeep, isArray } from 'lodash';
import { uuid } from '@/services/utils';
import { DialogService } from '@/services/common/dialog.service';
import {  ExternalContactService  } from '@/services/externalcontacts-service'
import  hrlogSetting  from '@/views/hrcodelog/hrlogSetting.vue'
import moment from 'moment';



@Component
export default class Externalcontacts extends Vue {
  public searchFormLoading: boolean = false;
  public loading: boolean = false;

  // i18n
  @Inject(I18nService) private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  @Inject(ExternalContactService) private externalContactService!: ExternalContactService;
  @Inject(DialogService) private dialogService!: DialogService;

  private searchConfig: ISearchConfig[] = [];
  private tableData: Dict<any> = [];
  private eventList: any[] = [];
  private form: any = {};
  private key: any = '';
  private pagination: any = {
    pageSize: 10,
    total: 0,
    pageNum: 1
  };

  private async getList(val?: any, type?: string) {
    let form: any = { pageNum: 1, pageSize: 10 };
    if (type === 'pagination') {
      const { pageSize, pageNum } = val;
      form.pageNum = pageNum;
      form.pageSize = pageSize;
      form = Object.assign({}, this.form, form);
    } else if (type === 'search') {
      form = Object.assign(form, val);
      form.pageSize = 10;
      form.pageNum = 1;
    }
    if (form.createTime && isArray(form.createTime)) {
      form.timeFrom = form.createTime[0];
      form.timeTo = form.createTime[1];
    }else{
      form.timeFrom = '';
      form.timeTo = '';
    }
    this.form = form;
    try {
      this.loading = true;
      // const resupdateState = await this.userUploadService.getUpdateState(form);
      const res = await this.externalContactService.getContactList({
        pageNum: form.pageNum,
        pageSize: form.pageSize,
        total: form.total,
        userId:form.userId,
        userName:form.userName,
        departmentName:form.departmentName,
        timeFrom: form.timeFrom,
        timeTo: form.timeTo,
        checkState:form.contactType
      });
      if (!res) {
        this.$notify.error({
          title: '错误',
          message: '获取列表失败，请您重试。'
        });
        return;
      }
      const { records, current, total, size } = res;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.pagination.pageNum = current;
      if (records && isArray(records)) {
        this.tableData = records;
        this.key = Date.now();
      }
    } finally {
      this.loading = false;
    }
  }

  private async initData() {
    this.searchConfig = cloneDeep(searchConfig);
    try {
      this.loading = true;
      await this.getList();
    } finally {
      this.loading = false;
    }
  }

  private async mounted(): Promise<void> {
    this.initData();
    // this.getAllList();
  }

  //重置按钮
  private selectChange(val: Dict<any>): void {
    this.form = val;
  }
// 下载方法 
  private successdownload(): void {
    this.loading = true
    const date = new Date();
    this.externalContactService.externalcontactdownload(
      this.form,
      `外部联系人列表${moment(date).format('YYYYMMDDHHmm')}.xlsx`
    ).then(res => {
      this.loading = false;
    })
  }
}
