import { ISearchConfig } from '@/components/SearchTable/index.d';


export const SELECTTYPE = [
  { label: '全部联系人', value:null },
  { label: '已验证外部联系人', value: '1' },
  {label:'未验证外部联系人',value:'0'}
]
export const searchConfig: ISearchConfig[] = [
  {
    category: 'common_content',
    subclass: 'employeeName',
    type: 'input',
    key: 'userName'
  },
  {
    category: 'common_content',
    subclass: 'adderNum',
    type: 'input',
    key: 'userId'
  },
  {
    category: 'common_content',
    subclass: 'adderDepart',
    type: 'input',
    key: 'departmentName'
  },
  {
    category: 'common_content',
    subclass: 'contactType',
    type: 'select',
    key: 'contactType',
    options: SELECTTYPE,
  },
  // {
  //   category: 'common_content',
  //   subclass: 'clientName',
  //   type: 'input',
  //   key: 'externalUserName'
  // },
  // {
  //   category: 'common_content',
  //   subclass: 'chatkeywords',
  //   type: 'input',
  //   key: 'chatkeywords'
  // },
  {
    category: 'common_content',
    subclass: 'add_time',
    extendType: 'datetimerange',
    type: 'datepicker',
    key: 'createTime',
    valueformat: 'yyyy-MM-dd HH:mm:ss'
  },
  
];
