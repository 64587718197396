import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class ExternalContactService {
  @Inject(HttpService) private http!: HttpService;
  
    //外部联系人列表下载表格数据接口
    public async externalcontactdownload(param: Dict<any>, name?: string): Promise<any> {
        return this.http.downloadFiles(
          `/api/clientele/external/contact/download`,
          {},
          param,
          name
        );
    }
  
    public getContactList(param: Dict<any>): Promise<any>{
      return this.http.post('/api/clientele/external/contact/query', param);
    }
  }
  